import { render, staticRenderFns } from "./MicrobialLoadOfNeatSemen.vue?vue&type=template&id=65552d03&scoped=true&"
import script from "./MicrobialLoadOfNeatSemen.vue?vue&type=script&lang=js&"
export * from "./MicrobialLoadOfNeatSemen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65552d03",
  null
  
)

export default component.exports