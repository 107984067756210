import { render, staticRenderFns } from "./yearAbstractOfMicrobialLoadOfFrozenSemen.vue?vue&type=template&id=696b2e84&scoped=true&"
import script from "./yearAbstractOfMicrobialLoadOfFrozenSemen.vue?vue&type=script&lang=js&"
export * from "./yearAbstractOfMicrobialLoadOfFrozenSemen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "696b2e84",
  null
  
)

export default component.exports